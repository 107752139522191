<template>
  <div v-if="path == '/clientes/finish' || path == '/5990/finish' || path == '/5690/finish' || path == '/5490/finish' || path == '/5190/finish' || path == '/4990/finish'" :class="cards">
      <div >

          <div class="box" v-if="screen > 800">
    
              <v-img contain :src="Promo" />


          </div>

          <div class="boxMovile" v-if="screen <= 800">
    
              <br>
              <v-img contain :src="PromoMobile"  />

          </div>

          </div>     

  </div>
</template>

<script>
import Cuotas from "@/assets/images/Cuotas.png";
import Promo from "@/assets/images/footer-southbridge-desktop.png";
import PromoMobile from "@/assets/images/footer-southbridge-mobile.png";

export default {
name: "Tarjetas",
mounted() {
},
data: () => ({
  path: null,
  screen: null,
  Cuotas,
  Promo,
  PromoMobile,
  cards:null,
}),
mounted() {
  this.path = window.location.pathname;

  this.screen = window.screen.width
  if (this.screen > 800) {
      this.cards = 'cards'
  } else {
      this.cards = 'cards2'
  }
},
methods: {

}
};
</script>
<style lang="css" scoped>
.box {
display: flex;
/* margin-bottom: -21.7%; */
/* background-color: #F3F3F3 !important; */
background-color: #F7E7D2;
width: 100%;  
}
.left {
padding: 10px;
width: 40%;
display: flex;
justify-content: center;
}

.right {
padding: 10px;
width: 90%;
/* background-color: yellowgreen; */
}
.images {
margin-top: 2%;
margin-bottom: 6%;
}
.payBoxtitle {
width: 750px;
height: 29px;
text-align: center;
font-family: 'Inter', sans-serif;
font-weight: bold;
font-size: 30px;
letter-spacing: -0.36px;
color: #2D387C;
text-transform: uppercase;
opacity: 1;
}
.imagesContainer {
display: flex;
justify-content: center;
align-items: center;
}

.imagesContainer>div {
background-color: #D80132;
flex-grow: 1;
padding: 10px;
text-align: center;
margin-right: 5%;
height: 80px;
display: flex;
align-items: center;
}
.btnKnowMore {
background-color: #EE811E !important;
color: #FFF !important;
border-radius: 8px;
font-weight: bold;
letter-spacing: 0.8px;
height: 20% !important;
width: 22%;
font-size: 16px !important;
text-align: center;
font-family: Arial, Helvetica, sans-serif;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 33px;
letter-spacing: 0.8px;
}
.boxMovile {
display: flex;
justify-content: center;
align-items: center;
margin-bottom: -38%;
background-color: #F7E7D2;
}
.payBoxtitleMovileBox {
margin-left: 2%;
}

.payBoxtitleMovile {
text-align: center;
font-family: 'Inter', sans-serif;
font-weight: bold;
color: #2D387C;
text-transform: uppercase;
opacity: 1;
font-size: 20px;
}
.imagesMobile {
margin-top: 6%;
margin-bottom: 6%;
width: 95%;
margin-left: 3%;
}
.imagesContainerMovile>div {
min-width: 70%;
background-color: #EB4949;
flex-grow: 1;
padding: 10px;
text-align: center;
margin-right: 5%;
height: 80px;
display: flex;
align-items: center;
}

.btnKnowMoreMovile {
width: 250px;
height: 50px !important;
flex-shrink: 0;
color: white !important;
font-weight: bold;
font-size: 16px !important;
letter-spacing: 0.8px;
background-color: #EE811E !important;
}
.cards{
  /* height: 105%; */
  margin-top: -4%;
  min-height: 230px;
}
.cards2{
  /* height: 105%; */
  /* margin-top: -4%; */
  margin-bottom: 40%;
}
</style>